import { SSRProvider, Toast, Toaster } from '@coolfire/ui';
import { getLocalTimeZone } from '@internationalized/date';
import type {
	LinksFunction,
	LoaderFunctionArgs,
	MetaFunction,
	SerializeFrom,
} from '@remix-run/node';
import type { useMatches } from '@remix-run/react';
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	data,
	useFetcher,
	useLoaderData,
} from '@remix-run/react';
import { parseAcceptLanguage } from 'intl-parse-accept-language';
import { useEffect } from 'react';
import { route } from 'routes-gen';
import { core } from '~/server/core.server';
import { DefaultErrorBoundary } from './components/sentry-error-boundary';
import { parseUserTimeZone } from './cookies';
import {
	DESKTOP_NOTIFICATION_DEVICE_TYPE,
	DesktopPushNotificationsProvider,
} from './providers/desktop-push-notifications-provider';
import { LocaleContextProvider } from './providers/locale-provider';
import { TimeZoneProvider } from './providers/time-zone-provider';
import { config } from './server/services/config.server';
import { readToasts } from './server/toasts.server';
import custom from './styles/custom.css?url';
import styles from './styles/tailwind.css?url';

type Match<Data> = ReturnType<typeof useMatches>[0] & { data: Data };

declare global {
	interface Window {
		GLOBALS: SerializeFrom<typeof loader>['GLOBALS'];
	}
}

export type RootHandle<LoaderData = unknown> = {
	/** Includes JS if present in at least one route */
	includeScripts?: true | ((match: Match<LoaderData>) => boolean);
};

export const links: LinksFunction = () => {
	return [
		{ rel: 'icon', href: '/favicon.ico', type: 'image/x-icon' },
		{ rel: 'manifest', href: '/manifest.webmanifest' },
		{ rel: 'stylesheet', href: styles },
		{ rel: 'stylesheet', href: custom },
		{
			rel: 'preload',
			href: '/fonts/Inter.var.woff2',
			as: 'font',
			type: 'font/woff2',
			crossOrigin: 'anonymous',
		},
		{
			rel: 'preload',
			as: 'image',
			type: 'image/svg+xml',
			href: '/sprite.svg',
		},
	];
};

export async function loader({ context, request, params }: LoaderFunctionArgs) {
	const acceptLanguage = request.headers.get('accept-language');
	const locales = parseAcceptLanguage(acceptLanguage, {
		validate: Intl.DateTimeFormat.supportedLocalesOf,
	});
	const headers = new Headers();
	const toastData = await readToasts(request, headers);
	const timeZone = parseUserTimeZone(request);
	const { workspaceSlug } = params;

	// Get user and workspace (if exist) for intercom
	const user = context.authInfo
		? await core.prisma.user.findFirst({
				where: {
					accountId: context.authInfo.id,
				},
			})
		: null;

	const device = user
		? await core.prisma.device.findFirst({
				where: {
					type: DESKTOP_NOTIFICATION_DEVICE_TYPE,
					userId: user.id,
				},
				select: {
					id: true,
					type: true,
					userId: true,
					pushToken: true,
				},
			})
		: null;

	const subdomain = request.url.split('.')[0].replace('https://', '');

	const intercomSettings =
		process.env.NODE_ENV === 'production'
			? {
					custom_launcher_selector: '#help_support',
					hide_default_launcher: true,
					email: user?.email ?? '',
					name: user ? `${user.firstName} ${user.lastName}` : '',
					user_id: user?.accountId ?? '',
					company: {
						id: subdomain,
						name: subdomain,
						website: config.API_URL,
					},
				}
			: null;

	return data(
		{
			GLOBALS: {
				environment: config.MODE,
				appUrl: config.API_URL,
				mapboxApiToken: config.MAPBOX_API_TOKEN,
				firebaseVapidKey: config.FIREBASE_VAPID_KEY,
				version: config.VERSION,
			},
			toastData,
			intercomSettings,
			timeZone,
			device,
			workspaceSlug,
			locales,
		},
		{ headers },
	);
}

export const meta: MetaFunction<typeof loader> = () => {
	return [{ title: 'Coolfire Core' }];
};

export function ErrorBoundary() {
	return (
		<DefaultErrorBoundary>
			<div>Something went wrong</div>
		</DefaultErrorBoundary>
	);
}

export default function App() {
	const { GLOBALS, toastData, intercomSettings, timeZone, device, workspaceSlug, locales } =
		useLoaderData<typeof loader>();
	const fetcher = useFetcher<unknown>();
	const localTimezone = getLocalTimeZone();

	useEffect(() => {
		if (toastData) {
			Toast.show(toastData);
		}
	}, [toastData]);

	useEffect(() => {
		if (!timeZone && localTimezone !== 'UTC') {
			fetcher.submit({ timeZone: localTimezone }, { method: 'POST', action: route('/core/tz') });
		}
	}, [timeZone, fetcher.submit, localTimezone]);

	return (
		<html lang="en" className="h-full text-gray-900 antialiased">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				{GLOBALS.environment === 'production' && (
					<script
						async
						// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
						dangerouslySetInnerHTML={{
							__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NJ6BF6T');`,
						}}
					/>
				)}
				{/* Intercom */}
				{intercomSettings && (
					<script
						// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
						dangerouslySetInnerHTML={{
							__html: `
            window.intercomSettings = ${JSON.stringify(intercomSettings)};
            if (window.Intercom) window.Intercom('update', window.intercomSettings);
          `,
						}}
					/>
				)}
				<Meta />
				<Links />
			</head>
			<body className="h-full">
				{/* Google Tag Manager - noscript */}
				{GLOBALS.environment === 'production' && (
					<noscript
						// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
						dangerouslySetInnerHTML={{
							__html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NJ6BF6T"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
						}}
					/>
				)}
				<TimeZoneProvider timeZone={timeZone ?? localTimezone}>
					<LocaleContextProvider locales={locales}>
						<SSRProvider locale={locales[0]}>
							<DesktopPushNotificationsProvider device={device} workspaceSlug={workspaceSlug}>
								<Outlet />
							</DesktopPushNotificationsProvider>
						</SSRProvider>
					</LocaleContextProvider>
				</TimeZoneProvider>
				<ScrollRestoration />
				<Scripts />
				<Toaster
					toastOptions={{
						position: 'top-right',
					}}
				/>
				<script
					suppressHydrationWarning
					// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
					dangerouslySetInnerHTML={{
						__html: `window.GLOBALS=${JSON.stringify(GLOBALS)};`,
					}}
				/>
			</body>
		</html>
	);
}
